import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import VueSignaturePad from "vue-signature-pad";
import "./registerServiceWorker";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import "@socotec.io/socio-vue-components/src/assets/scss/main.scss";
import wb from "./registerServiceWorker";
import { initializeAuthHooks } from "@/setup/auth-hooks";
import SocioManifest from "../socio-manifest.json";
import PackageJson from "../package.json";
import { initializeTracingAndLogging } from "@socotec.io/socio-vue-components";
import socioGrpcClient from "./setup/socioGrpcClient";
import * as Sentry from "@sentry/vue";

initializeTracingAndLogging(SocioManifest, PackageJson, {
  maxExportBatchSize: 10,
  router,
  useDocumentLoadInstrumentation: false,
});
store.$client = socioGrpcClient;
Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;
Vue.use(SocioVueComponents);
Vue.use(VueSignaturePad);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
initializeAuthHooks();
const envConfig = {
  i18nLocale: process.env.VUE_APP_I18N_LOCALE,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  mapBoxApiKey: process.env.VUE_APP_MAP_BOX,
};
Vue.use(AosComponents, { store, envConfig });

Sentry.init({
  Vue,
  dsn: "https://52fe65c7ba042978300875f60e531310@sentry.tools.infra-socotec.net/2",

  // This enables automatic instrumentation (highly recommended),
  // but is not necessary for purely manual usage
  // If you only want to use custom instrumentation:
  // * Remove the `BrowserTracing` integration
  // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
      }
    }),
    Sentry.browserTracingIntegration({ router })
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.VUE_APP_SOCIO_GRPC_API_ENV,
  release: `rapsosps@${process.env.VUE_APP_VERSION}`,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");




